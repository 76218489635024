@import '../../assets/css/variables.base.scss';

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
      color: #337ab7;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

  .btn-hq-color {
    background-color: #337ab7;
  }

  .btn-hq-color:hover {
    background-color: #fff;
    color: #337ab7;
  }
}
