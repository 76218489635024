@import '../../assets/css/variables.base.scss';

.reset-password-form {
  .submit-button {
    margin-top: 10px;
    background-color: #337ab7;
  }

  .submit-button:hover {
    background-color: #fff;
    color: #337ab7;
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
    color: #337ab7;
    a {
      color: #337ab7;
    }
  }
}
